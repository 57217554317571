.ant-card-meta-title {
    font-size: 1em;
    text-align: center;
    font-weight: 800;
    text-transform: uppercase;
}
.ant-row.about {
    padding: 0;
}
.vision-desc {
    text-align: justify;
}
.teams-col {
    margin: 0 !important;
}
.ant-card-meta-description {
    color: #000;
    font-size: 1.3em;
    text-align: center;
    font-weight: 600;
}
.about-agent {
    color: #66758c;
    text-align: justify;
    margin-top: 1em;
}
.about-cover {
    width: 100%;
    border-radius: 40px;
    margin-left: -2em;
    height: 110%;
    margin-top: 1em;
}
.vision-div {
    padding-top: 3em;
    padding-left: 4em;
}
.vision-cover {
    border-radius: 2em;
}
.about-row {
    padding: 2em;
    padding-top: 6em;
}
.about-title {
    padding: 8em 3em;
    text-align: justify;
    padding-bottom: 0;
}
.description-about {
    font-size: 1.1rem;
    padding-right: 3em;
    color: rgba(124, 139, 161, 1);
}
.team-desc {
    margin: 1em auto;
    font-size: 1.2rem;
    color: rgba(124, 139, 161, 1);
    text-align: center;
}
.value-row {
    margin: 0 5em;
    margin-top: -3em;
}
.value-padding {
    padding: 5em;
}
.value-item {
    height: 18em;
    border: none;
    padding: 0 1.5rem;
    margin: 0;
}
.team-col {
    padding-top: 5em;
}

@media (max-width: 1280px) {
    .vision-div {
        padding-top: 5em;
    }
    .about-page-contact {
        display: none;
    }
    .description-about {
        text-align: justify;
        font-size: 1rem;
    }
    .team-desc {
        width: 80%;
        font-size: 1rem;
        margin: 0 auto;
        padding-bottom: 1em;
        text-align: center;
    }
}
@media (max-width: 1024px) {
    .subDescription.value-item {
        height: auto;
    }
    .vision-div {
        padding-top: 0;
    }
    .about-row {
        padding-top: 0;
    }
    .vision-cover {
        height: 100%;
    }
    .value-row {
        margin: 0;
        margin-top: -2em;
    }
    .description-about {
        font-size: 14px;
    }
    .value-padding {
        padding: 0;
    }
    .vision-highlight {
        margin-top: 0;
    }
    .vision-desc {
        padding-right: 0;
        font-size: 1em;
    }
}

@media (max-width: 820px) {
    .about-cover {
        height: 100%;
    }
}
@media (max-width: 576px) {
    .about-title {
        padding: 0;
    }
    .value-row {
        margin-top: 0;
    }
    .subDescription.value-item {
        margin-top: 1em;
    }
    .description-about {
        padding-right: 0;
    }
    .about-cover {
        display: none;
    }
    .vision-div {
        padding-top: 3em;
        padding-left: 0;
    }
}

@media (max-width: 414px) {
    .about-row {
        padding: 0;
    }
    .vision-cover {
        border-radius: 10px;
        margin: 0;
    }
    .vision-div {
        padding: 0 1em;
    }
    .team-col {
        padding: 0;
    }
    .vision-highlight {
        margin-top: 2em;
    }
    .about-title {
        margin: 0;
        padding: 0 1em;
        padding-top: 2em;
    }
}
@media (max-width: 280px) {
    .subDescription.value-item {
        margin-top: 0;
    }
    .team-desc {
        width: 100%;
        font-size: 12px;
    }
    .values-icon {
        font-size: 1.5em;
    }
    .vision-cover {
        border-radius: 0;
    }
}
