.ant-card-head-title,
.ant-card-body {
    text-decoration: none !important;
}
.unScroll {
    background-color: #fcfcfc;
}
.home {
    background-color: #fff;
    .ant-row {
        padding: 1.5em;
        .service-ro {
            padding: 0;
        }
    }
    .ant-row.ant-form-item {
        padding: 0;
    }
    .ant-row.expertise-row {
        padding-top: 6em;
    }
    .ant-row.about {
        padding: 0;
    }
}
.lmia-service {
    margin-left: 4em;
}
.cover-row {
    padding: 2em 0;
    padding-bottom: 0;
    .stats-content {
        padding: 3em 1em;
    }
}
.coverNav {
    padding: 0 1em;
    background-image: linear-gradient(182deg, #594ae363, transparent);
}
.wrapper {
    width: 100%;
    height: 100vh;
    overflow: hidden;
}

.wrapper video {
    object-fit: cover;
    width: 100%;
    height: 100%;
}

video::-webkit-media-controls {
    display: none !important;
}
ul.pro-service {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    list-style-type: disclosure-closed;
}
.cover-landing {
    margin-top: 2.5em;
    height: 85vh;
    background: url("../../../public//static//icons/cover.png");
    background-size: cover;
}
.cover-header,
.category-highlight {
    color: white;
}
.category-highlight {
    font-size: 15px;
}

.landing-page {
    padding: 0;
}
.specialize-card {
    background: "#222363";
    border: none;
}
.sub-text {
    font-size: 1.5em;
    font-weight: 800;
    line-height: 2;
    --tw-translate-x: 0;
    --tw-translate-y: 0;
    --tw-rotate: 0;
    --tw-skew-x: -12deg;
    --tw-skew-y: 0;
    --tw-scale-x: 1;
    --tw-scale-y: 1;
    padding: 0 1rem;
    transform: translateX(var(--tw-translate-x))
        translateY(var(--tw-translate-y)) rotate(var(--tw-rotate))
        skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y))
        scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
    --tw-bg-opacity: 1;
    background-color: #594ae382;
    --tw-text-opacity: 1;
    color: #fff;
}
.ant-btn-round.ant-btn-lg.cover-btn {
    margin: 1.5em 0;
    height: 50px;
}
.ant-btn-round.ant-btn-lg.noMargin {
    margin: 0;
}
.service-row {
    padding: 0 10em;
}
.highlightText {
    color: #594ae3;
}
.highlight-stats {
    color: #594ae3;
    font-size: 1.2em;
    text-align: left;
    font-weight: 800;
}
.textCenter {
    text-align: center;
}
.header-text {
    font-size: 2.8rem;
    font-weight: 800;
    line-height: 1.2;
}
.description-stats {
    font-size: 1.2em;
    color: rgba(124, 139, 161, 1);
}
.contact-home-form {
    margin: 2em 0;
}
div.testimonial-btn > div:nth-child(4) {
    opacity: 0;
}
.testimonial-card,
.testimonial-card .ant-card-meta-description {
    background: #f8fafc;
    color: rgba(0, 0, 0, 0.85);
}
.testimonial-rating {
    padding: 0 13em;
}
.stats-numbers {
    display: flex;
    justify-content: space-between;
    width: 80%;
    padding-top: 1em;
}
.quote {
    font-size: 1.1rem;
    line-height: 1.6;
    color: rgba(74, 85, 104, 1);
}
.googleUser {
    margin-top: -0.8rem;
}
button.MuiButtonBase-root.MuiIconButton-root.MuiIconButton-sizeMedium {
    opacity: 1;
}
.ant-rate {
    color: rgba(246, 173, 85, 1);
}
.testimonial-avatar {
    display: flex;
    text-align: center;
}
.title-testimonial {
    margin-bottom: 1rem;
    font-size: 1.25rem;
    font-weight: 700;
}
.aboutImgSpan {
    border: none;
}
.subDescription {
    display: flex;
    height: 26em;
    -webkit-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column;
    max-width: 27em;
    -webkit-align-items: center;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    --tw-border-opacity: 1;
    padding: 2.5rem 1.5rem;
    border: 2px dashed rgba(100, 21, 255, var(--tw-border-opacity));
    border-radius: 0.5rem;
    margin: 3rem auto;
    margin-bottom: 0;
}
.service-image {
    width: 10em;
    border-width: 2px;
    border: none;
    text-align: center;
    border-radius: 9999px;
    padding: 0;
    color: #594ae3;
}
.service-image img {
    border-radius: 50%;
    width: 8em;
    height: 8em;
}
.subtext {
    margin-top: 1.5rem;
}
.subtext,
.valuesTitle {
    text-align: center;
}
.valuesTitle {
    margin-top: 0;
}
.service-title {
    margin-top: 0.5rem;
    font-weight: 700;
    font-size: 1.25rem;
    line-height: 1;
    color: #594ae3;
}
.service-desc {
    margin-top: 0.75rem;
    font-weight: 600;
    color: rgba(124, 139, 161, 1);
    font-size: 0.875rem;
    line-height: 2;
}
.padTop {
    margin-top: 5em;
}
.expertise-cover {
    margin-top: 3em;
    border-radius: 40px;
}
.steps-img {
    margin-top: 3em;
    border-radius: 40px;
}
.testimonial {
    margin-top: 4em;
}
.stepDescription {
    margin-top: 0.75rem;
    max-width: 20rem;
    line-height: 1.8;
    font-size: 0.875rem;
    --tw-text-opacity: 1;
    color: rgba(113, 128, 150, var(--tw-text-opacity));
    font-weight: 500;
}
.contact-home {
    padding-top: 8em;
    padding-left: 3em;
}
h4 {
    color: #fff;
    font-size: 2em;
    text-align: center;
}
.contact-desc {
    line-height: 2;
}
.fi {
    font-weight: 600;
    font-size: 2.25rem;
    line-height: 1;
    color: rgba(203, 213, 224, 1);
}
h6 {
    line-height: 1;
    font-size: 1.25rem;
    font-weight: 600;
}
.ant-btn-round {
    font-weight: 700;
    border-radius: 10px !important;
}
.ant-list-item-meta-title {
    font-weight: 700;
    color: #594ae3 !important;
}
.ant-list-item-meta-description {
    font-weight: 500;
    color: rgba(36, 62, 99, 1);
}
.ant-list-item-meta-title {
    font-weight: 700;
    font-size: 1.2rem;
    text-align: left;
}
.ant-collapse,
.ant-collapse.service-collapse {
    background: transparent;
    color: black;
    font-weight: 700;
    border: none;
}
.ant-collapse > .ant-collapse-item {
    padding-top: 1em 2rem;
    text-align: left;
    font-size: 1.25rem;
    background-color: rgba(237, 242, 247, 1);
    margin-top: 1rem;
    border: none;
    border-radius: 1em;
    font-weight: 500;
}
.ant-collapse-content {
    border: none;
    background: rgb(237 242 246);
    border-radius: 1em;
}
.ant-collapse > .ant-collapse-item:last-child,
.ant-collapse > .ant-collapse-item:last-child > .ant-collapse-header {
    border-radius: 1em !important;
}
.ant-collapse-content > .ant-collapse-content-box {
    background-color: rgba(237, 242, 247, 1);
    font-size: 1rem;
    font-weight: 500;
    border: none;
    border-bottom-right-radius: 1em;
    border-bottom-left-radius: 1em;
}
.values-icon {
    font-size: 2rem;
    color: #594ae3;
}
.titleService,
span.ant-tree-title {
    margin-top: 0.5rem;
    font-weight: 500;
    font-size: 1rem;
    line-height: 1;
}
span.ant-tree-title {
    line-height: 1.5;
}
.valuesDescription {
    text-align: justify;
}
.stats-count {
    font-size: 1.5rem;
    font-weight: 800;
}
.stats-title {
    color: rgba(80, 17, 204, 1);
    font-size: 1.2em;
}
.step-manual {
    padding-left: 2em;
    margin-top: 15%;
}
.stats-cover {
    padding-right: 5em;
}

@media (max-width: 1280px) {
    .wrapper {
        height: auto;
    }
    .sub-text {
        font-size: 1.5rem;
    }
    .header-text {
        font-size: 2.5rem;
    }
    .testimonial-stats {
        width: 78%;
        margin: 10px auto;
    }
    .contact-home {
        padding-top: 5em;
    }
    .ant-btn-round.ant-btn-lg.cover-btn {
        height: 45px;
    }
    .ant-btn-round.ant-btn-lg.noMargin {
        margin: 0;
    }
}
@media (max-width: 1024px) {
    .sub-text {
        font-size: 1.2em;
    }
    .expertise-cover {
        margin-top: 7em;
        border-radius: 20px;
    }
    .subDescription {
        height: auto;
    }
    .subDescription,
    .prof-service {
        margin: 10px;
    }
    .navbar {
        font-size: 14px;
    }
    .header-text {
        font-size: 1.8rem;
    }
    .testimonial-stats {
        width: 78%;
        margin: 10px auto;
    }
    .description-stats,
    .stats-title {
        font-size: 1em;
    }
    .contact-home {
        padding-top: 5em;
    }
    .ant-btn-round.ant-btn-lg.cover-btn,
    .ant-btn-round.ant-btn-lg {
        height: 35px;
        font-size: 14px;
    }
    .ant-btn-round.ant-btn-lg.noMargin {
        margin: 0;
    }
    .step-manual {
        margin-top: 10%;
    }
    .stats-numbers {
        width: 100%;
    }
}
@media (max-width: 992px) {
    .header-text {
        font-size: 1.5rem;
    }
}

@media (max-width: 768px) {
    .btn {
        width: 100%;
    }
}
@media (max-width: 576px) {
    .wrapper {
        display: none;
    }
    .expertise-cover {
        margin-top: 0;
    }
    .home .ant-row {
        padding: 1em;
    }
    .step-manual {
        margin-top: 0;
    }
    .testimonial {
        margin-top: 0;
        padding: 0 1em;
    }
    .ant-row.ant-form-item.emailContact {
        width: 100%;
        padding: 0;
        padding-bottom: 1em;
    }
    .contact-row-home {
        padding: 0;
    }
    .sub-text {
        width: 90%;
        margin: 1em auto;
    }
    .cover-landing {
        margin-top: 0;
        height: auto;
        background: url("../../../public//static/icons/cover.png");
        background-size: cover;
        background-attachment: fixed;
    }
    .cover-row .stats-content {
        padding: 0;
    }
    .stats-content {
        display: flex;
        flex-direction: column;
    }
    .header-text {
        font-size: 2rem;
        line-height: 1.5;
    }
    .cover-col {
        padding: 6em 1em 1em 1em;
        text-align: center;
    }
    .padTop {
        margin-top: 0;
    }
    .stats-cover {
        padding-right: 0;
    }
    .contact-home {
        padding: 0;
    }
    .home .ant-row.expertise-row {
        padding-top: 0;
    }
    .testimonial-rating {
        padding: 0;
    }
    button.MuiButtonBase-root.MuiIconButton-root.MuiIconButton-sizeMedium {
        opacity: 0;
    }
    .mobile-aivc
        button.MuiButtonBase-root.MuiIconButton-root.MuiIconButton-sizeMedium {
        opacity: 1;
    }
    .contact-desc {
        line-height: 1.6;
    }
}

@media (max-width: 414px) {
    .contact-desc {
        line-height: 1.6;
    }
    .social-text-link,
    .social-footer {
        font-size: 14px;
        text-align: center;
    }
    .footer-container {
        padding: 1em;
    }
    .home {
        padding: 0;
    }
    .social-icons {
        margin-top: 1em;
    }
    .contact-home-form {
        margin: auto;
    }
    .sub-text,
    .stats-count,
    .title-testimonial {
        font-size: 1rem;
    }
    .social-logo {
        font-size: 1.5rem;
        margin: 0;
    }
    .ant-btn-round.ant-btn-lg.cover-btn {
        font-size: 14px;
        height: 35px;
        margin: 1em 0;
    }
    .ant-btn-round.ant-btn-lg.noMargin {
        margin: 0;
    }
    .img-logo-footer {
        margin-top: 0;
    }
    .stats-title,
    .quote {
        font-size: 0.8rem;
    }
    .steps-img {
        display: none;
    }
    .stats-cover {
        padding-right: 0;
        padding-bottom: 2em;
    }
    .contact-home {
        padding: 0;
    }
}

@media (max-width: 375px) {
    .header-text {
        line-height: 1.5;
    }
    .contact-desc {
        line-height: 1.6;
    }
    .social-text-link,
    .social-footer {
        font-size: 14px;
        text-align: center;
    }
    .social-icons {
        margin-top: 1em;
    }
    .sub-text,
    .description-stats,
    .stats-count {
        font-size: 1rem;
    }
    .subDescription {
        height: auto;
    }
    .subDescription,
    .prof-service {
        margin: 0;
        margin-bottom: 2em;
    }
    .social-logo {
        font-size: 1.5rem;
        margin: 0;
    }
    .ant-btn-round.ant-btn-lg.cover-btn {
        font-size: 12px;
        height: 30px;
        margin: 1em 0;
    }
    .ant-btn-round.ant-btn-lg.noMargin {
        margin: 0;
    }
    .img-logo-footer {
        margin-top: 0;
    }
    .stats-title,
    .quote {
        font-size: 0.9rem;
    }
    .cover-col {
        line-height: 2;
    }
    .stats-cover {
        display: none;
    }
    .contact-home {
        padding: 0;
    }
}

@media (max-width: 280px) {
    .header-text {
        font-size: 1rem;
        line-height: 2;
    }
    .cover-row {
        padding: 0;
        padding-top: 2em;
    }
    .cover-landing {
        margin-top: 10px;
    }
    .sub-text {
        width: 80%;
        margin: 1em auto;
    }
    .description-stats {
        width: 100%;
    }
    button.MuiButtonBase-root.MuiIconButton-root.MuiIconButton-sizeMedium {
        display: none;
    }
    .mobile-aivc
        button.MuiButtonBase-root.MuiIconButton-root.MuiIconButton-sizeMedium {
        display: block;
    }
    .service-image img {
        width: 7em;
        height: 6em;
        margin: 0 auto;
    }
    .service-title,
    .highlight-stats,
    h6 {
        font-size: 14px;
    }
    .service-desc {
        font-weight: 500;
        font-size: 13px;
        line-height: 1.5;
    }
    .stepDescription {
        line-height: 1.5;
        font-size: 12px;
    }
    .contact-desc {
        line-height: 1.6;
    }
    .social-text-link,
    .social-footer,
    .titleService {
        font-size: 12px;
        text-align: center;
    }
    .social-icons,
    .social-footer {
        margin: 1em 0;
    }
    .footer-container {
        padding: 0;
    }
    .home {
        padding: 0;
    }
    .social-icons,
    .ant-timeline {
        margin-top: 1em;
    }
    .sub-text,
    .description-stats,
    .stats-count,
    .category-highlight,
    .ant-list-item-meta-title,
    .ant-list-item-meta-description {
        font-size: 12px;
    }
    .social-logo {
        font-size: 1.5rem;
        margin: 0;
    }
    .ant-btn-round.ant-btn-lg.cover-btn {
        font-size: 10px;
        height: 30px;
        margin: 1em 0;
        padding: 0 1em;
    }
    .ant-btn-round.ant-btn-lg.noMargin {
        margin: 0;
    }
    .img-logo-footer {
        margin-top: 0;
    }
    .stats-title,
    .quote {
        font-size: 11px;
    }
    .cover-col {
        padding: 0;
        padding-top: 5em;
    }
    .stats-cover {
        padding-right: 0;
    }
    .contact-home {
        padding: 0;
    }
}
