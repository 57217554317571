.ant-card.program-card {
    padding-top: 2em;
    background: #594ae3;
    border-radius: 20px;
    ul {
        text-align: left;
        margin-left: 2em;
        list-style-type: square;
    }
}
.programs-meta {
    height: 23em;
}
.programs-meta .ant-card-meta-title {
    font-size: 1.2rem;
}
.info-desc {
    width: 70%;
    background: #594ae324;
    color: #594ae3;
    margin: 0 auto;
    padding: 1em 0.2em;
    border-radius: 20px;
}
.programs-meta .ant-card-meta-description,
.programs-meta .ant-card-meta-title {
    color: white;
    text-align: left;
    white-space: normal;
}
.programs-meta .ant-card-meta-description {
    font-weight: normal;
    font-size: 1rem;
}
.programs-img {
    width: 10em !important;
    height: 10em !important;
    margin: 0 auto;
}
img.programs-img {
    border-radius: 50% !important;
}
li.item {
    border: 1px solid #fafafa;
    padding: 5px;
    margin-bottom: 8px;
    border-radius: 7px;
}
.program-cardTitle {
    font-size: 2.2rem;
    font-weight: 800;
}
.pricing-cost {
    font-size: 3rem;
    color: #fff;
}
.program-card-margin {
    margin: 10px 0;
}
.program-action-btn {
    margin-top: 1em;
    color: #11143d;
    font-weight: 600;
}
@media (max-width: 1280px) {
    .programs-meta {
        height: auto;
    }
}
@media (max-width: 375px) {
    .program-cardTitle {
        font-size: 25px;
    }
}
@media (max-width: 280px) {
    .program-cardTitle {
        font-size: 22px;
    }
    .ant-card.program-card {
        border-radius: 10px;
    }
    .ant-card-meta-detail > div:not(:last-child) {
        margin: 10px 0;
    }
    .programs-meta .ant-card-meta-title {
        font-size: 16px;
    }
    .pricing-cost {
        font-size: 40px;
    }
    .programs-img {
        width: 6em !important;
        height: 6em !important;
    }
    .ant-card-body {
        padding: 0;
    }
    .programs-meta .ant-card-meta-description {
        font-size: 14px;
    }
}
